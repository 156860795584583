import { Component, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { StorageService } from '@services/logic/storage.service';
import { TranslatePipe } from '@app/pipes/translate.pipe';

@Component({
    imports: [TranslatePipe],
    selector: 'app-video-dialog',
    templateUrl: './videoDialog.html',
    styleUrls: ['../dialogs.scss', './videoDialog.scss']
})
export class VideoDialogComponent {
  public language: string;
  title: string;
  vidChoice: String;

  constructor(
    private storage: StorageService,
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.vidChoice = data.vidChoice;
    this.language = this.storage.lang.value ?? 'dk';
  }

  close(res: any = null): void {
    this.dialogRef.close(res);
  }
}
