<div class="dialog_container">
  <div class="dialog_header">
    <h4>{{title}}</h4>
  </div>

  @if (vidChoice == 'howItWorks') {
  <div class="dialog__panel">
    @if (language == 'dk') {
    <div class="videoWrapper">
      <iframe
        width="720"
        height="420"
        src="https://www.youtube.com/embed/DBxfsq8yJIg?si=9_CdZkG4iOUPsy1T"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    } @if (language == 'en') {
    <div class="videoWrapper">
      <iframe
        width="720"
        height="420"
        src="https://www.youtube.com/embed/DBxfsq8yJIg?si=9_CdZkG4iOUPsy1T"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    }
  </div>
  } @if (vidChoice == 'cancelTask') {
  <div class="dialog__panel">
    <div class="videoWrapper">
      <iframe
        width="720"
        height="420"
        src="https://www.youtube.com/embed/-2QO5BnRwRg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  } @if (vidChoice == 'payment') {
  <div class="dialog__panel">
    <div class="videoWrapper">
      <iframe
        width="720"
        height="420"
        src="https://www.youtube.com/embed/8H4oTEaugPg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
  }

  <div class="dialog__footer">
    <button class="footer__button cancel__button" (click)="close()">
      {{ 'PAGES.HOW_IT_WORKS.CLOSE' | translate }}
    </button>
  </div>
</div>
